import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/website-icon.png'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="buildforCause - empowering nonprofits" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>One Cause at a Time</h2>
                </header>
                <p>
                "build for Cause" is committed to help nonprofits by helping to inspire their audience/stakeholders  
                via portraying the efforts in digital mediums and managing the data efficiently.
                </p>
                <ul className="actions">
                  <li>
                    <Link to="/generic" className="button">
                      Learn More
                    </Link>
                  </li>
                </ul>
              </div>
              <span className="image">
                <img src={pic01} alt="" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>We specialize in...</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Website & Portals</h3>
                <p>
                Website to showcase the good deeds and Content management 
                with required features - Portal, User System, eCommerce etc
                </p>
              </li>
              <li>
                <span className="icon major style3 fas fa-gears"></span>
                <h3>Campaign Automatoin</h3>
                <p>
                Setting up a smooth campaign automation (Newsletter, Social Media, 
                Goal based etc.) system to easy to use tools.
                </p>
              </li>
              <li>
                <span className="icon major style5 fas fa-money"></span>
                <h3>Fundraising</h3>
                <p>
                A painless fundraising system with affordable tools with the 
                features to integrate to all you existing mode of campaigns.
                </p>
              </li>
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Many More...
                  </Link>
                </li>
              </ul>
            </footer>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Our Impact</h2>
              <p>
                We are trying to make good impact by supporting the nonprofit organizations 
                <br />
                who all are already or due for inspiring the world for the better. 
                <br />
                Since 2019, we’ve donated over 600 hours of skills.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-code-fork"></span>
                <strong>4</strong> Projects
              </li>
              <li className="style2">
                <span className="icon fa-users"></span>
                <strong>3</strong> Volunteers
              </li>
              <li className="style3">
                <span className="icon fa-signal"></span>
                <strong>600</strong> hours
              </li>
              <li className="style4">
                <span className="icon fa-laptop"></span>
                <strong>4</strong> Servers
              </li>
              <li className="style5">
                <span className="icon fa-diamond"></span>
                <strong>$1000+</strong> Funds Raised
              </li>
            </ul>
            <p className="content">
              
            </p>
            <footer className="major">
              <ul className="actions">
                <li>
                  Mail us at <a href="mailto:hi@buildforcause.com">hi@buildforcause.com</a> to know more.
                </li>
              </ul>
            </footer>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Say a hello!</h2>
              <p>
                Please contact buildforCause by sending an email to <a href="mailto:hi@buildforcause.com">hi@buildforcause.com</a> explaining your needs.
                <br />
                We'll make sure you're getting an reply as well as the help your organization requires. Best!
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="https://wa.me/14049414116" className="button special">
                  <span className="icon fas fa-whatsapp"></span> WhatsApp!
                  </a>
                </li>
                <li>                
                  <a href="https://m.me/buildforcause" className="button">
                  <span className="icon style5 fas fa-facebook"></span> Messenger!
                  </a>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
