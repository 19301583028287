import React from 'react'

import logo from '../assets/images/logo.svg';

const Header = (props) => (
    <header id="header" className="alt">
        <h1>build for Cause</h1>
        <p>empowering nonprofits to inspire more via digital mediums<br />
        by donating skills & hours.</p>
    </header>
)

export default Header
